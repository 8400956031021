import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { Swiper, SwiperSlide } from 'swiper/react';
import { graphql, useStaticQuery } from 'gatsby';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autoplay, Navigation } from 'swiper';

import 'swiper/css/bundle';
import 'swiper/css/navigation';
import useResizer from '@components/extra/useResizer';
import RawHTML from '@components/Common/RawHTML/index';
import { cn } from '@/lib/utils';
import { dataScienceProjects } from '@src/constants/data-science';
import useDataScienceProgram from '@src/hooks/useDataScienceProgram';

const ProjectsCarousel = (props) => {
  const isWithoutLabels = props.isWithoutLabels;

  const { isDataSciencePage } = useDataScienceProgram();

  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);
  const swiperRef = React.useRef(null);
  const isMobile = useResizer();
  //   const isMobile = useResizer();
  const data = useStaticQuery(graphql`
    query {
      projects: allFile(
        filter: { relativePath: { regex: "images/v5/ProjectsCards/" } }
      ) {
        nodes {
          name
          childImageSharp {
            gatsbyImageData(
              blurredOptions: {}
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP]
            )
          }
        }
      }
    }
  `);

  let projects = isDataSciencePage
    ? dataScienceProjects
    : props.projects || [
        {
          title: (
            <>
              Work like Full Stack <br></br>Developers at Amazon
            </>
          ),
          subtitle: (
            <>
              Build a functional and<br></br>scalable ecommerce app.
            </>
          ),
          tag: 'Guided Project',
          image: 'CardOne',
          skills: ['MongoDB', 'React JS', 'Node JS', 'Express JS'],
        },
        {
          title: (
            <>
              Build an OTT platform<br></br>like Netflix Engineers
            </>
          ),
          subtitle: (
            <>
              Build a highly responsive, seamless<br></br> video streaming
              service.
            </>
          ),
          tag: 'Independent Project',
          image: 'CardTwo',
          skills: ['React JS', 'Node JS', 'Rest API', 'Express JS'],
        },
        {
          title: (
            <>
              Work like Backend<br></br>Developers at Zomato
            </>
          ),
          subtitle: (
            <>
              Build a high-scale distributed web<br></br>backend for a food
              ordering app.
            </>
          ),
          tag: 'Guided Project',
          image: 'CardFour',
          skills: ['MongoDB', 'Java', 'Spring Boot', 'Redis'],
        },
        {
          title: (
            <>
              Experience building a<br></br>Google News like app
            </>
          ),
          subtitle: (
            <>
              Build a fully functional news<br></br>aggregator like google news.
            </>
          ),
          tag: 'Independent Project',
          image: 'CardSix',
          skills: ['HTML', 'CSS', 'Rest APIs', 'Flexbox'],
        },
        {
          title: (
            <>
              Work like Frontend<br></br> Developers at AirBnB
            </>
          ),
          subtitle: (
            <>
              Build a highly responsive<br></br>frontend for a travel app.
            </>
          ),
          tag: 'Guided Project',
          image: 'CardThree',
          skills: ['HTML', 'CSS', 'Bootstrap', 'React JS'],
        },
        {
          title: (
            <>
              Work like an<br></br>Engineer at Zerodha
            </>
          ),
          subtitle: (
            <>
              Build a tool to analyse an<br></br>investment portfolio.
            </>
          ),
          tag: 'Guided Project',
          image: 'CardFive',
          skills: ['OOP', 'JSON', 'Gradle', 'Rest API'],
        },
        {
          title: (
            <>
              Work like a senior<br></br>developer at Flipkart
            </>
          ),
          subtitle: (
            <>
              Experience working on a live<br></br>distributed system in AWS.
            </>
          ),
          tag: 'Guided Project',
          image: 'CardSeven',
          skills: ['Microservices', 'Kafka', 'Scaling', 'Cloud'],
        },
      ];

  const SLIDES_PER_VIEW_DESKTOP = 3;
  const settings = {
    slidesPerView: 1,
    breakpoints: {
      768: {
        slidesPerView: SLIDES_PER_VIEW_DESKTOP,
      },
    },
    id: 'projects-carousel',
    loop: true,
    spaceBetween: 50,
    speed: 300,
    grabCursor: true,
    autoplay: {
      delay: 5000,
      disableOnInteraction: true,
    },
    enabled: projects.length > SLIDES_PER_VIEW_DESKTOP || isMobile,
  };

  return (
    <div className="relative w-full" id="projects-carousel">
      <Swiper
        ref={swiperRef}
        {...settings}
        navigation={{
          prevEl: navigationPrevRef.current,
          nextEl: navigationNextRef.current,
        }}
        autoplay
        modules={[Navigation, Autoplay]}
        className={`projectCarousal w-[75vw] md:w-full`}
      >
        {projects.map((project) => {
          const projectImage = data.projects.nodes.find(
            (ele) => ele.name == project.image,
          );
          const image = getImage(projectImage?.childImageSharp);

          return (
            <SwiperSlide
              key={project.title}
              className={cn(
                'min-h-[380px] sm:h-[475px]',
                `${isWithoutLabels ? 'h-[400px] md:h-[390px]' : ''}`,
              )}
            >
              <div
                className={cn(
                  'flex h-full flex-col items-center justify-between gap-2 rounded-[20px] bg-v5-neutral-200 p-5',
                  `${isWithoutLabels ? 'justify-start' : ''}`,
                )}
              >
                {project.tag ? (
                  <span
                    className={`rounded-full ${
                      project.tag === 'Guided Project' ||
                      project.tag === 'Advanced Guided Project'
                        ? 'bg-v5-green-400'
                        : 'bg-v5-green-200'
                    } px-2 py-1 font-rubik text-xs font-bold text-white`}
                  >
                    {project.tag}
                  </span>
                ) : (
                  <></>
                )}
                <div>
                  {project.staticImage ? (
                    project.staticImage
                  ) : (
                    <GatsbyImage
                      loading="lazy"
                      image={image}
                      placeholder="blurred"
                      alt={'Learning'}
                      imgStyle={{ objectFit: 'contain' }}
                      className="h-full w-full max-w-[200px]"
                    />
                  )}
                </div>
                <div className="text-center">
                  <h3 className={`font-manrope text-lg font-black`}>
                    <RawHTML>{project.title}</RawHTML>
                  </h3>
                  <p className="font-rubik  text-sm text-v5-neutral-400">
                    <RawHTML>{project.subtitle}</RawHTML>
                  </p>
                </div>
                {project.skills.length ? (
                  <div className="flex flex-wrap justify-center gap-x-2 gap-y-2">
                    {project.skills.map((el) => (
                      <div
                        className="min-w-[100px] overflow-hidden text-ellipsis whitespace-nowrap rounded-full border-2 border-black py-1 px-3 text-center font-rubik text-xs  "
                        key={el}
                      >
                        {el}
                      </div>
                    ))}
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
      {(projects.length > SLIDES_PER_VIEW_DESKTOP || isMobile) && (
        <>
          <div
            onClick={() => swiperRef.current.swiper.slidePrev()}
            ref={navigationPrevRef}
            className="absolute top-1/2 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer  hover:opacity-75 sm:-left-10"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-left']}></FontAwesomeIcon>
          </div>{' '}
          <div
            onClick={() => swiperRef.current.swiper.slideNext()}
            ref={navigationNextRef}
            className="absolute top-1/2 right-0 z-10 -translate-x-1/2 -translate-y-1/2 transform cursor-pointer hover:opacity-75 sm:-right-10"
          >
            <FontAwesomeIcon icon={['fas', 'chevron-right']}></FontAwesomeIcon>
          </div>
        </>
      )}
    </div>
  );
};

export default ProjectsCarousel;
