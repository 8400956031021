import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

export const dataScienceProjects = [
  {
    title: (
      <>
        Work like a Stock
        <br />
        Analyst at Zerodha
      </>
    ),
    subtitle: <>Analyze the stock performance trends of top tech companies</>,
    tag: 'Guided Project',
    staticImage: (
      <StaticImage
        loading="lazy"
        src="../images/DataScience/projects/Project1.webp"
        placeholder="blurred"
        alt="Illustration of a computer screen with the Microsoft Excel logo and a candlestick chart, accompanied by a magnifying glass to emphasize examination or analysis."
        width={200}
      />
    ),
    skills: ['MS Excel', 'Data Visualization', 'Pivot tables', 'Data cleaning'],
  },
  {
    title: (
      <>
        Build Insights like
        <br />
        Flipkart Growth team
      </>
    ),
    subtitle: <>Create dynamic dashboards highlighting trends and insights</>,
    tag: 'Independent Project',
    staticImage: (
      <StaticImage
        loading="lazy"
        src="../images/DataScience/projects/Project2.webp"
        placeholder="blurred"
        alt="A green folder with a pie chart and a shopping cart, illustrating the relationship between analytics and shopping trends."
        width={200}
      />
    ),
    skills: [
      'Data Visualization',
      'PowerBI',
      'Tableau',
      'Business Intelligence',
    ],
  },
  {
    title: (
      <>
        Work like a Business
        <br />
        Analyst at Zomato
      </>
    ),
    subtitle: <>Unlock order insights and customer patterns from data</>,
    tag: 'Guided Project',
    staticImage: (
      <StaticImage
        loading="lazy"
        src="../images/DataScience/projects/Project3.webp"
        placeholder="blurred"
        alt="Diagram depicting SQL application, emphasizing data insights for Zomato's business analysis of orders and customers."
        width={200}
      />
    ),
    skills: ['SQL', 'Data Analysis', 'Business insights', 'Data aggregation'],
  },
  {
    title: (
      <>
        Work like an <br />
        Uber Data analyst
      </>
    ),
    subtitle: (
      <>
        Uncover behavioral data from <br />
        Uber rides
      </>
    ),
    tag: 'Independent Project',
    staticImage: (
      <StaticImage
        loading="lazy"
        src="../images/DataScience/projects/Project4.webp"
        placeholder="blurred"
        alt="An illustration of Python programming for data analytics, emphasizing the application of Pandas and Seaborn in data manipulation and analysis."
        width={200}
      />
    ),
    skills: ['Seaborn', 'Statistics', 'Pandas', 'Python'],
  },
  {
    title: (
      <>
        Work like a Price
        <br />
        Analyst at Spinny
      </>
    ),
    subtitle: <>Analyze data to predict best selling prices for used cars</>,
    tag: 'Guided Project',
    staticImage: (
      <StaticImage
        loading="lazy"
        src="../images/DataScience/projects/Project5.webp"
        placeholder="blurred"
        alt="A guide on using NumPy, Pandas and Matplotlib to create reports, tailored for price analysts predicting used car prices at Spinny."
        width={200}
      />
    ),
    skills: ['NumPy', 'Pandas', 'Matplotlib', 'Regression Modelling'],
  },
  {
    title: (
      <>
        Work like ML <br />
        Engineers at MakeMyTrip
      </>
    ),
    subtitle: (
      <>
        Create a multi-agent holiday <br />
        itinerary planner
      </>
    ),
    tag: 'Guided Project',
    staticImage: (
      <StaticImage
        loading="lazy"
        src="../images/DataScience/projects/Project6.webp"
        placeholder="blurred"
        alt="Visual representation of a program using AI Solutions to develop a multi-agent holiday itinerary planner for travelers at Make My Trip."
        width={200}
      />
    ),
    skills: ['AI Agent', 'Python', 'APIs', 'ML'],
  },
];
